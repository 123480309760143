import React, { Fragment, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { AuthContext } from "../../context/Auth.tsx";
import { EstablishmentContext } from "src/context/Establishment.tsx";
import { UIContext } from "src/context/UI.tsx";

import Dropdown from "../components/Dropdown.tsx";

export const Navbar = () => {
  const { t } = useTranslation();

  const { setDropdownOpen } = useContext(UIContext);
  const { logout, currentUser } = useContext(AuthContext);

  const { establishments, selectedEstablishment, setSelectedEstablishment } =
    useContext(EstablishmentContext);

  const postLogout = async () => {
    logout();
  };

  const handleNav = (e: any) => {
    if (!selectedEstablishment) e.preventDefault();
  };

  return (
    <Fragment>
      <div className="navbar">
        <ul className="navbar-nav navbar-logo">
          <li className="logo">
            <button className="nav-link nav-link-logo">
              <div className="image-logo-container">
                <img className="logo-image" src="/res/symbioz.png" alt="" />
              </div>
              <span className="link-text">
                <h1 className="text-center">Symbioz</h1>
              </span>
            </button>
          </li>
        </ul>
        <ul className="navbar-nav navbar-home">
          <hr />

          <li className="nav-item">
            <NavLink className="nav-link" to="/establishment">
              <span className="msymbol material-symbols-outlined">
                storefront
              </span>
              <span className="link-text">{t("establishment.title")}</span>
            </NavLink>
          </li>

          <hr />

          <div className="nav-dropdown-container">
            <Dropdown
              className="full-width"
              selected={
                selectedEstablishment && selectedEstablishment.trade_name
              }
              title={
                selectedEstablishment
                  ? selectedEstablishment.trade_name
                  : t("commons.select_establishment")
              }
            >
              {establishments.map((item) => {
                return (
                  <a
                    key={item.establishment_id + "_left"}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setSelectedEstablishment(item);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {item.trade_name}
                  </a>
                );
              })}
            </Dropdown>
          </div>

          <li className="nav-item">
            <NavLink
              className={
                "nav-link " + (!selectedEstablishment ? "disabled" : "")
              }
              to="/payments"
              onClick={(e) => handleNav(e)}
              style={{
                opacity: selectedEstablishment ? 1 : 0.15,
              }}
            >
              <span className="msymbol material-symbols-outlined">
                payments
              </span>
              <span className="link-text">{t("payments.title")}</span>
            </NavLink>
            <NavLink
              className={
                "nav-link " + (!selectedEstablishment ? "disabled" : "")
              }
              to="/orders"
              onClick={(e) => handleNav(e)}
              style={{
                opacity: selectedEstablishment ? 1 : 0.15,
              }}
            >
              <span className="msymbol material-symbols-outlined">
                contract
              </span>
              <span className="link-text">{t("orders.title")}</span>
            </NavLink>
            <NavLink
              className={
                "nav-link " + (!selectedEstablishment ? "disabled" : "")
              }
              to="/staff_tracking"
              onClick={(e) => handleNav(e)}
              style={{
                opacity: selectedEstablishment ? 1 : 0.15,
              }}
            >
              <span className="msymbol material-symbols-outlined">
                folder_shared
              </span>
              <span className="link-text">{t("staff_tracking.title")}</span>
            </NavLink>

            <NavLink
              className={
                "nav-link " + (!selectedEstablishment ? "disabled" : "")
              }
              to="/prints"
              onClick={(e) => handleNav(e)}
              style={{
                opacity: selectedEstablishment ? 1 : 0.15,
              }}
            >
              <span className="msymbol material-symbols-outlined">print</span>
              <span className="link-text">{t("prints.title")}</span>
            </NavLink>

            {currentUser?.username === "nf" && (
              <NavLink
                className={
                  "nav-link " + (!selectedEstablishment ? "disabled" : "")
                }
                to="/jet-entries"
                onClick={(e) => handleNav(e)}
                style={{
                  opacity: selectedEstablishment ? 1 : 0.15,
                }}
              >
                <span className="msymbol material-symbols-outlined">
                  eye_tracking
                </span>
                <span className="link-text">{t("jetEntries.title")}</span>
              </NavLink>
            )}

            <NavLink
              className={
                "nav-link " + (!selectedEstablishment ? "disabled" : "")
              }
              to="/database"
              onClick={(e) => handleNav(e)}
              style={{
                opacity: selectedEstablishment ? 1 : 0.15,
              }}
            >
              <span className="msymbol material-symbols-outlined">
                database
              </span>
              <span className="link-text">{t("database.title")}</span>
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav navbar-logout">
          <li className="nav-item">
            <a className="nav-link" key={"logout"} onClick={postLogout}>
              <span className="msymbol material-symbols-outlined danger">
                power_settings_new
              </span>
              <span className="link-text">{t("auth.logout")}</span>
            </a>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};

export default Navbar;
