import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthContext } from '../../context/Auth.tsx'

export const Auth = () => {
  const { login } = useContext(AuthContext)
  const { t } = useTranslation()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isError, setIsError] = useState(false)

  const postLogin = async () => {
    setIsError(false)

    const valid_request = await login({ username, password })
    if (valid_request === true) {
      setIsError(false)
    } else {
      setIsError(true)
    }
  }

  const handleUsername = (event: any) => {
    setUsername(event.target.value)
  }
  const handlePassword = (event: any) => {
    setPassword(event.target.value)
  }

  return (
    <div className="basic-container auth-container">
      <div className="login-title">
        <div className="image-logo-container">
          <img className="logo-image" src="/res/symbioz.png" alt="" />
        </div>
        <span className="logo-text">
          <h1 className="text-center">Symbioz</h1>
        </span>
      </div>

      <form
        onSubmit={(event) => {
          event.preventDefault()
          postLogin()
        }}
        className="flex-column white-box login-box"
      >
        <div className="field">
          <label className="label">{t('auth.username')}</label>

          <div className="control has-icons-left">
            <input
              className={`full-width input ${isError ? 'is-danger' : ''}`}
              type="text"
              placeholder={t('auth.username')}
              value={username}
              onChange={handleUsername}
            />
            <span className="icon is-small is-left">
              <span className="msymbol material-symbols-outlined">person</span>
            </span>
          </div>
        </div>

        <div className="field">
          <label className="label">{t('auth.password')}</label>

          <div className="control has-icons-left">
            <input
              className={`full-width input ${isError ? 'is-danger' : ''}`}
              type="password"
              placeholder={t('auth.password')}
              value={password}
              onChange={handlePassword}
            />
            <span className="icon is-small is-left">
              <span className="msymbol material-symbols-outlined">lock</span>
            </span>
          </div>
        </div>

        <button
          className="button is-primary-gradient login-button"

          type="submit"
        >
          {t('auth.login_action')}
        </button>

        {isError && <p className="help is-danger">{t('auth.login_error')}</p>}
      </form>
    </div>
  )
}

export default Auth
