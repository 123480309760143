import React, { useContext, useRef } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EstablishmentContext } from "src/context/Establishment";
import { UIContext } from "src/context/UI";

import {
  getCsvOrdersExport,
  getCsvPaymentExport,
  getCsvStaffTrackingExport,
} from "src/services/establishments";

import Dropdown from "../components/Dropdown";

export const Establishment = () => {
  const { t } = useTranslation();

  const { setDropdownOpen } = useContext(UIContext);
  const { establishments, selectedEstablishment, setSelectedEstablishment } =
    useContext(EstablishmentContext);

  const toastId = useRef<any>(null);

  const csvPaymentExport = async (id_estab: number) => {
    toastId.current = toast.promise(getCsvPaymentExport(id_estab), {
      pending: t("establishment.pending_csv"),
      success: t("establishment.success_csv"),
      error: t("establishment.error_csv"),
    });
  };

  const csvOrderExport = async (id_estab: number) => {
    toastId.current = toast.promise(getCsvOrdersExport(id_estab), {
      pending: t("establishment.pending_csv"),
      success: t("establishment.success_csv"),
      error: t("establishment.error_csv"),
    });
  };

  const csvStaffTrackingExport = async (id_estab: number) => {
    toastId.current = toast.promise(getCsvStaffTrackingExport(id_estab), {
      pending: t("establishment.pending_csv"),
      success: t("establishment.success_csv"),
      error: t("establishment.error_csv"),
    });
  };

  return (
    <div className="basic-container">
      <div className="sbz-title-container flex-row">
        <span className="title-icon msymbol material-symbols-outlined">
          storefront
        </span>

        <div className="flex-column">
          <h1 className="sbz-title">{t("establishment.title")}</h1>
        </div>
      </div>

      {establishments && (
        <div className="sbz-page-container">
          {establishments.map((item) => (
            <div
              key={item.establishment_id + "_list"}
              className={
                "flex-row full-width white-box estab-item flex-center " +
                (selectedEstablishment &&
                selectedEstablishment.establishment_id === item.establishment_id
                  ? "active"
                  : "")
              }
            >
              <span className="estab-icon msymbol material-symbols-outlined">
                storefront
              </span>
              <div className="flex-1 flex-column">
                <h2>{item.trade_name}</h2>
                <label style={{ fontStyle: "italic", opacity: 0.5 }}>
                  <b>{item.establishment_id}</b> - {item.access_key}
                </label>
              </div>
              {/* <button
            className="button is-link is-light"
            onClick={() => csvPaymentExport(item.establishment_id)}
          >
            download csv
          </button> */}

              <div className="flex-row flex-center">
                <Dropdown title="Options" rightMenu={true}>
                  <Link
                    to="/payments"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setSelectedEstablishment(item);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t("payments.title")}
                  </Link>
                  <Link
                    to="/orders"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setSelectedEstablishment(item);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t("orders.title")}
                  </Link>
                  <Link
                    to="/staff_tracking"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setSelectedEstablishment(item);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t("staff_tracking.title")}
                  </Link>
                  <Link
                    to="/database"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      setSelectedEstablishment(item);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t("database.title")}
                  </Link>
                  <hr style={{ height: 1, margin: "0.5rem 0" }} />
                  <a
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      csvPaymentExport(item.establishment_id);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t('establishment.download_payments')}
                  </a>
                  <a
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      csvOrderExport(item.establishment_id);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t('establishment.download_orders')}
                  </a>
                  <a
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={(e) => {
                      csvStaffTrackingExport(item.establishment_id);
                      setDropdownOpen(null);
                    }}
                    className="dropdown-item"
                  >
                    {t('establishment.download_staff_tracking')}
                  </a>
                </Dropdown>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Establishment;
