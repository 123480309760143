import React, { Dispatch, useContext, useEffect, useState } from "react";

import { getEstablishments } from "../services/establishments.ts";

import { AuthContext } from "./Auth.tsx";

type EstablishmentContextType = {
  establishments: any[];
  selectedEstablishment: any;
  setSelectedEstablishment: Dispatch<any>;
};

export const EstablishmentContext =
  React.createContext<EstablishmentContextType>({
    establishments: [],
    selectedEstablishment: null,
    setSelectedEstablishment: () => {},
  });

type Props = {
  children: React.ReactNode;
};

export function EstablishmentProvider({ children }: Props) {
  const { isLoggedIn } = useContext(AuthContext);
  const [establishments, setEstablishments] = useState<Array<any>>([]);
  const [selectedEstablishment, setSelectedEstablishment] = useState<any>(null);

  useEffect(() => {
    if (isLoggedIn === true) {
      const fetchData = async () => {
        return await getEstablishments();
      };

      fetchData()
        .then((res) => {
          setEstablishments(res.data);
        })
        .catch((err) => {
          setEstablishments([]);
          console.log(err);
        });
    }
  }, [isLoggedIn]);

  useEffect(() => {
    const selectedEtab = localStorage.getItem("selectedEtab");
    if (selectedEtab) {
      setSelectedEstablishment(JSON.parse(selectedEtab));
    }
  }, []);

  useEffect(() => {
    const selectedEtab = localStorage.setItem(
      "selectedEtab",
      JSON.stringify(selectedEstablishment)
    );
  }, [selectedEstablishment]);

  return (
    <EstablishmentContext.Provider
      value={{
        establishments: establishments,
        selectedEstablishment: selectedEstablishment,
        setSelectedEstablishment: setSelectedEstablishment,
      }}
    >
      {children}
    </EstablishmentContext.Provider>
  );
}
