export const database = {
  title: `Database`,

  generate_dump: `Generate a dump file`,
  generate_new_dump: `Generate a new dump file`,
  generating_dump: `Generating`,
  download_dump: `Download dump file`,

  pending_csv: `Dump file generating`,
  success_csv: `Downloading`,
  error_csv: `An error occured while downloading`,

  pending_generating: `Chargement...`,
  success_generating: `Generating, the dump file will be available soon`,
  error_generating: `An error occured while generating`,
};
