export const auth = {
  login: `Connexion`,
  logout: `Déconnexion`,

  username: `Utilisateur`,
  password: `Mot de passe`,

  login_action: `Se connecter`,
  login_error: `Utilisateur ou mot de passe incorrect`,
};
