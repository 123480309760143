import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import { EstablishmentContext } from "src/context/Establishment";

import {
  generateSqlDump,
  getSqlDump,
  getSqlDumpAvailability,
} from "src/services/establishments";

export const DatabaseAccess = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedEstablishment } = useContext(EstablishmentContext);
  const [dumpAvailability, setDumpAvailability] = useState<boolean>(false);
  const [dumpGenerating, setDumpGenerating] = useState<boolean>(false);

  const toastId = useRef<any>(null);

  useEffect(() => {
    if (selectedEstablishment) {
      const reloadDumpAvailability = async () => {
        return await getSqlDumpAvailability(
          selectedEstablishment.establishment_id
        );
      };

      reloadDumpAvailability()
        .then((result: any) => {
          setDumpAvailability(result.data.finished_dump);
          setDumpGenerating(result.data.generating_dump);
        })
        .catch((err) => {
          setDumpAvailability(false);
          setDumpGenerating(false);
          console.log(err);
        });
    }
  }, [selectedEstablishment]);

  const getMySQLdump = async () => {
    toastId.current = toast.promise(
      getSqlDump(selectedEstablishment.establishment_id),
      {
        pending: t("database.pending_download"),
        success: t("database.success_download"),
        error: t("database.error_download"),
      }
    );
  };

  const generateMySQLdump = async () => {
    toastId.current = toast.promise(
      async () => {
        await generateSqlDump(selectedEstablishment.establishment_id);
        setDumpGenerating(true);
        setDumpAvailability(false);
      },
      {
        pending: t("database.pending_generating"),
        success: t("database.success_generating"),
        error: t("database.error_generating"),
      }
    );
  };

  if (!selectedEstablishment) {
    navigate("/establishment");
  } else {
    return (
      <div className="basic-container">
        <div className="sbz-title-container flex-row">
          <span className="title-icon msymbol material-symbols-outlined">
            database
          </span>

          <div className="flex-column">
            <h1 className="sbz-title">{t("database.title")}</h1>
            <label>{selectedEstablishment.trade_name}</label>
          </div>
        </div>

        <div className="sbz-page-container flex-center">
          <div
            className="white-box no-padding flex-column"
            style={{
              margin: 0,
              marginTop: "4.25rem",
              width: "100%",
              maxWidth: "32rem",
              paddingRight: 0,
            }}
          >
            <div className="title-box flex-row">
              <h3>Mysql 5.7 - credentials</h3>
            </div>

            <div className="container-box flex-column">
              <div className="flex-row">
                <p style={{ margin: 0, fontWeight: "bold" }}>Host</p>
                <div className="flex-1"></div>
                <p style={{ margin: 0 }}>db_host</p>
              </div>
              <div className="flex-row">
                <p style={{ margin: 0, fontWeight: "bold" }}>Port</p>
                <div className="flex-1"></div>
                <p style={{ margin: 0 }}>db_port</p>
              </div>
              <div className="flex-row">
                <p style={{ margin: 0, fontWeight: "bold" }}>Database</p>
                <div className="flex-1"></div>
                <p style={{ margin: 0 }}>db_name</p>
              </div>
              <div className="flex-row">
                <p style={{ margin: 0, fontWeight: "bold" }}>Username</p>
                <div className="flex-1"></div>
                <p style={{ margin: 0 }}>db_user</p>
              </div>
              <div className="flex-row">
                <p style={{ margin: 0, fontWeight: "bold" }}>Passwrord</p>
                <div className="flex-1"></div>
                <p style={{ margin: 0 }}>db_pswd</p>
              </div>

              <button
                disabled={dumpGenerating}
                className="button is-primary"
                style={{ marginTop: "0.75rem", marginBottom: "0.25rem" }}
                onClick={() => generateMySQLdump()}
              >
                {dumpGenerating? t('database.generating_dump') : dumpAvailability
                  ? t("database.generate_new_dump")
                  : t("database.generate_dump")}
              </button>
              <button
                disabled={!dumpAvailability}
                className="button is-primary"
                style={{ marginTop: "0.25rem", marginBottom: "0.25rem" }}
                onClick={() => getMySQLdump()}
              >
                {t("database.download_dump")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default DatabaseAccess;
