export const commons = {
  previous: `Vorige`,
  next: `Volgende`,
  options: `Opties`,

  no_event: `Geen evenementen om nu te tonen`,

  select_establishment: 'Selecteer een vestiging',

  module: `Module`,
  kind: `Type`,

  total_amount: `Totale bedrag`,
  amount: `Bedrag`,

  total_events: `Totaal aantal evenementen`,
  total_type: `Totaal per type`,
  total_staff: `Totaal per personeelslid`,
  total_payment_mode: `Totaal per betaalmethode`,

  id: `ID`,
  status: `Status`,
  date: `Datum`,
  reduc_novat: `Korting excl. BTW`,
  reduc: `Korting incl. BTW`,
  total_novat: `Totaal excl. BTW`,
  total: `Totaal incl. BTW`,
  vat: `BTW`
};