export const commons = {
  previous: "Previous",
  next: "Next",
  options: "Options",

  no_event: `No events to show for now`,

  select_establishment: 'Select establishment',

  module: `Module`,
  kind: `Kind`,

  total_amount: `Total amount`,
  amount: `Amount`,

  total_events: `Total events`,
  total_type: `Total by types`,
  total_staff: `Total by staff`,
  total_payment_mode: `Total by payment modes`,

  id: `ID`,
  status: `Status`,
  date: `Date`,
  reduc_novat: `Reduction no VAT`,
  reduc: `Reduction no VAT`,
  total_novat: `Total no VAT`,
  total: "Total",
  vat: `VAT`
};
