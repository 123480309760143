export const establishment = {
  title: `Establishments`,

  pending_csv: `Csv file generating`,
  success_csv: `Downloading`,
  error_csv: `An error occured`,

  download_payments: `Download payments as csv`,
  download_orders: `Download orders as csv`,
  download_staff_tracking: `Download Staff Tracking as csv`,
};
