import axios from "axios";

// const BASE_URL = "http://localhost:3000";
const BASE_URL = "https://spf-api.symbioz.io";

export const getIsLoggedIn = () => {
  return axios.get(`${BASE_URL}/is_logged_in`, { withCredentials: true });
};

export const postLogin = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  return axios.post(
    `${BASE_URL}/login`,
    {
      username: username,
      password: password,
    },
    { withCredentials: true }
  );
};

export const postLogout = () => {
  return axios.post(
    `${BASE_URL}/logout`,
    {},
    {
      withCredentials: true,
    }
  );
};
