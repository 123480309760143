import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { EstablishmentContext } from "src/context/Establishment";

import { getStaffTrackingPage } from "src/services/establishments";

import AnimatedLoader from "../components/AnimatedLoader";
import Paginator from "../components/Paginator";

export const StaffTracking = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedEstablishment } = useContext(EstablishmentContext);
  const [idLoadedEstablishment, setIdLoadedEstablishment] = useState<
    number | null
  >(null);

  const [staffTracking, setStaffTracking] = useState<any>(null);

  const [totalEvents, setTotalEvents] = useState<number | null>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);


  const changePage = (page_number: number) => {
    setCurrentPage(page_number);
  };

  useEffect(() => {
    if (selectedEstablishment) {
      if (idLoadedEstablishment !== selectedEstablishment.establishment_id) {
        setCurrentPage(1);

        setStaffTracking(null);
      } else {
        setStaffTracking(null);
      }

      const reloadStaffTracking = async () => {
        setIdLoadedEstablishment(selectedEstablishment.establishment_id);
        return await getStaffTrackingPage(
          selectedEstablishment.establishment_id,
          currentPage
        );
      };

      reloadStaffTracking()
        .then((result: any) => {
          setStaffTracking(result.data);
          setTotalEvents(result.data.total_events);
        })
        .catch((err) => {
          setTotalEvents(0);
          setStaffTracking(null);
          console.log(err);
        });
    }
  }, [selectedEstablishment, currentPage]);

  if (!selectedEstablishment) {
    navigate("/establishment");
  } else {
    return (
      <div className="basic-container">
        <div className="sbz-title-container flex-row">
          <span className="title-icon msymbol material-symbols-outlined">
            folder_shared
          </span>

          <div className="flex-column">
            <h1 className="sbz-title">{t("staff_tracking.title")}</h1>
            <label>{selectedEstablishment.trade_name}</label>
          </div>
        </div>

        {totalEvents !== null && (
          <div className="sbz-page-container">
            <div className="white-box" style={{ marginBottom: "1rem" }}>
              <div className="flex-row">
                <h2 className="flex-1" style={{ fontWeight: "bold" }}>
                  {t("commons.total_events")}:
                </h2>
                <h2 style={{ fontWeight: 900 }}>
                  {totalEvents.toLocaleString("fr-FR")}
                </h2>
              </div>
            </div>
          </div>
        )}

        {!staffTracking && (
          <div className="sbz-page-container">
            <AnimatedLoader />
          </div>
        )}

        {staffTracking && staffTracking?.result?.length === 0 && (
          <div className="sbz-page-container flex-center">
            <h1 style={{ margin: "2.5rem", fontSize: "1.25rem", opacity: 0.5 }}>
              {t('commons.no_event')}
            </h1>
          </div>
        )}

        {staffTracking !== null && staffTracking?.result?.length > 0 && (
          <div className="sbz-page-container">
            <div className="flex-column table-list">
              <div className="flex-row full-width table-item header-item">
                <div
                  style={{
                    width: "5rem",
                  }}
                >
                  {t("commons.id")}
                </div>
                <div className="flex-1">
                  {t("commons.date")}
                </div>
                <div
                  style={{
                    width: "10rem",
                  }}
                >
                  {t("staff_tracking.staff")}
                </div>
                <div
                  style={{
                    width: "15rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.module")}
                </div>
                <div
                  style={{
                    width: "15rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.kind")}
                </div>
              </div>

              <hr style={{ height: 1, background: "#00000022", margin: 0 }} />
              {staffTracking.result.map((item: any, index: number) => (
                <div
                  key={item.staff_tracking_id}
                  className={
                    "flex-row table-item " + (index % 2 === 0 ? "even" : "odd")
                  }
                >
                  <div style={{ width: "5rem", fontWeight: 700 }}>
                    {item.staff_tracking_id}
                  </div>
                  <div className="flex-1">
                    {new Date(item.date).toLocaleString("fr-FR")}
                  </div>
                  <div style={{ width: "10rem" }}>{item.staff_name}</div>
                  <div style={{ width: "15rem", textAlign: "right" }}>
                    {item.module}
                  </div>
                  <div style={{ width: "15rem", textAlign: "right" }}>
                    {item.kind}
                  </div>
                </div>
              ))}
            </div>

            <Paginator
              currentPage={currentPage}
              totalItem={staffTracking.total_events}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </div>
        )}
      </div>
    );
  }
};

export default StaffTracking;
