import React, { useContext, useRef, useState } from 'react'
import { UIContext } from 'src/context/UI'

type Props = {
  title: string
  className?: string
  selected?: boolean
  rightMenu?: boolean
  children: React.ReactNode
}

export const Dropdown = ({
  children,
  title,
  className,
  selected,
  rightMenu,
}: Props) => {
  const { dropdownOpen, setDropdownOpen } = useContext(UIContext)
  const currentId = useRef('id' + Math.random().toString(16).slice(2))
  return (
    <div
      className={
        'dropdown ' +
        (dropdownOpen === currentId.current ? 'is-active ' : '') +
        className
      }
    >
      <div className="dropdown-trigger full-width">
        <button
          className="button dropdown-button full-width"
          aria-controls="dropdown-menu"
          onClick={() => {
            if (dropdownOpen) {
              setDropdownOpen(null)
            } else {
              setDropdownOpen(currentId.current)
            }
          }}
          onBlur={() => {
            setDropdownOpen(null)
          }}
        >
          <span
            className={'text-ellipsis flex-1 ' + (selected ? 'font-bold' : '')}
          >
            {title}
          </span>

          <span className="icon is-small">
            {dropdownOpen === currentId.current ? (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_up
              </span>
            ) : (
              <span className="msymbol material-symbols-outlined">
                keyboard_arrow_down
              </span>
            )}
          </span>
        </button>
      </div>

      <div
        className="dropdown-menu"
        id="dropdown-menu"
        role="menu"
        style={
          rightMenu ? { right: '0.5rem', left: 'unset' } : { left: '0.5rem' }
        }
      >
        <div className="dropdown-content">{children}</div>
      </div>
    </div>
  )
}

export default Dropdown
