import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { EstablishmentContext } from "src/context/Establishment";

import { getJetEntriesPage } from "src/services/establishments";

import AnimatedLoader from "../components/AnimatedLoader";
import Paginator from "../components/Paginator";
import Modal from "../components/Modal";

import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale
);

export const JetEntries = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedEstablishment } = useContext(EstablishmentContext);
  const [idLoadedEstablishment, setIdLoadedEstablishment] = useState<
    number | null
  >(null);

  const [jetEntries, setJetEntries] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [nfModalSig, setNfModalSig] = useState<string | null>(null);
  const [showNfModal, setShowNfModal] = useState<boolean>(false);

  const changePage = (page_number: number) => {
    setCurrentPage(page_number);
  };

  useEffect(() => {
    if (selectedEstablishment) {
      // Go ta page one if selected establishment changes
      if (idLoadedEstablishment !== selectedEstablishment.establishment_id) {
        setCurrentPage(1);
        setJetEntries(null);
      } else {
        setJetEntries(null);
      }

      const relaodJetEntries = async () => {
        setIdLoadedEstablishment(selectedEstablishment.establishment_id);
        return await getJetEntriesPage(
          selectedEstablishment.establishment_id,
          currentPage
        );
      };

      relaodJetEntries()
        .then((result: any) => {
          setJetEntries(result.data);
        })
        .catch((err) => {
          setJetEntries(null);
          console.log(err);
        });
    }
  }, [selectedEstablishment, currentPage]);

  if (!selectedEstablishment) {
    navigate("/establishment");
  } else {
    return (
      <>
        <Modal
          isShown={showNfModal}
          closeModal={() => {
            setShowNfModal(false);
          }}
        >
          <h1 style={{ fontWeight: "bold", marginBottom: "1rem" }}>
            Signature comlplète:
          </h1>
          <p style={{ wordWrap: "break-word", marginBottom: "1rem" }}>
            {nfModalSig}
          </p>
        </Modal>
        <div className="basic-container">
          <div className="sbz-title-container flex-row">
            <span className="title-icon msymbol material-symbols-outlined">
              eye_tracking
            </span>

            <div className="flex-column">
              <h1 className="sbz-title">{t("jetEntries.title")}</h1>
              <label>{selectedEstablishment.trade_name}</label>
            </div>
          </div>

          {(!jetEntries || !jetEntries?.results) && (
            <div className="sbz-page-container">
              <AnimatedLoader />
            </div>
          )}

          {jetEntries?.total !== undefined && (
            <div className="sbz-page-container">
              <div className="white-box" style={{ marginBottom: "1rem" }}>
                <div className="flex-row">
                  <h2 className="flex-1" style={{ fontWeight: "bold" }}>
                    {t("commons.total_events")}:
                  </h2>
                  <h2 style={{ fontWeight: 900 }}>
                    {jetEntries?.total.toLocaleString("fr-FR")}
                  </h2>
                </div>
              </div>
            </div>
          )}

          {jetEntries !== null && jetEntries?.results?.length === 0 && (
            <div className="sbz-page-container flex-center">
              <h1
                style={{ margin: "2.5rem", fontSize: "1.25rem", opacity: 0.5 }}
              >
                {t("commons.no_event")}
              </h1>
            </div>
          )}

          {jetEntries !== null && jetEntries?.results?.length > 0 && (
            <div className="sbz-page-container">
              <div className="flex-column table-list">
                <div className="flex-row full-width table-item header-item">
                  <div
                    style={{
                      width: "12rem",
                    }}
                  >
                    {t("commons.id")} - code jet
                  </div>
                  <div className="flex-1">{t("jetEntries.event_name")}</div>

                  <div
                    style={{
                      width: "10rem",
                      textAlign: "right",
                    }}
                  >
                    {t("commons.date")}
                  </div>
                </div>

                <hr style={{ height: 1, background: "#00000022", margin: 0 }} />

                {jetEntries.results.map((item: any, index: number) => (
                  <div
                    key={item.payment_id + "_list_100"}
                    className={
                      "table-item flex-row " +
                      (index % 2 === 0 ? "even" : "odd")
                    }
                  >
                    <div
                      style={{
                        width: "12rem",
                      }}
                    >
                      <p
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          paddingRight: "0.5rem",
                        }}
                      >
                        <b>{item.nf_jet_entry_id}</b>
                      </p>

                      <span
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          opacity: 0.5,
                        }}
                      >
                        <i>code jet : </i>{" "}
                        <b
                          className={
                            item.status === "PAYED"
                              ? "success-text"
                              : item.status === "CANCELLED"
                              ? "danger-text"
                              : "warn-text"
                          }
                        >
                          {item.event_code}
                        </b>
                      </span>
                    </div>

                    <div className="flex-column flex-1">
                      <div className="flex-row full-width">
                        <div className="flex-1 flex-column">
                          <p>{item.event_description}</p>
                          <p style={{ fontStyle: "italic", opacity: 0.5 }}>
                            {item.comment}
                          </p>
                        </div>

                        {item?.signature && (
                          <div
                            style={{
                              width: "20rem",
                              textAlign: "right",
                              opacity: 0.5,
                              fontSize: "0.9rem",
                              marginTop: "0.05rem",
                              cursor: "pointer"
                            }}
                            onClick={() => {
                              setNfModalSig(item?.signature);
                              setShowNfModal(true);
                            }}
                          >
                            {item?.signature?.slice(0, 16)} ...{" "}
                            {item?.signature?.slice(
                              item?.signature?.length - 16,
                              -1
                            )}
                          </div>
                        )}

                        <div
                          style={{
                            width: "12rem",
                            textAlign: "right",
                            fontWeight: 700,
                          }}
                        >
                          {new Date(item.created_at).toLocaleString("fr-FR")}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <Paginator
                currentPage={currentPage}
                totalItem={jetEntries.total}
                itemPerPage={50}
                changeCurrentPage={changePage}
              />
            </div>
          )}
        </div>
      </>
    );
  }
};

export default JetEntries;
