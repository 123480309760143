import React from "react";

export const AnimatedLoader = () => {
  return (
    <div className="animated-loader-container full-width flex-column flex-center">
      <div className="animated-loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default AnimatedLoader;
