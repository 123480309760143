import React, { Dispatch, useState } from "react";

type UIContextType = {
  dropdownOpen: string | null;
  setDropdownOpen: Dispatch<string | null>;
};

export const UIContext = React.createContext<UIContextType>({
  dropdownOpen: null,
  setDropdownOpen: () => {},
});

type Props = {
  children: React.ReactNode;
};

export function UIProvider({ children }: Props) {
  const [dropdownOpen, setDropdownOpen] = useState<string | null>(null);

  return (
    <UIContext.Provider
      value={{
        dropdownOpen: dropdownOpen,
        setDropdownOpen: setDropdownOpen,
      }}
    >
      {children}
    </UIContext.Provider>
  );
}
