import React, { useContext, useEffect, useState } from "react";

import { Bar, Doughnut } from "react-chartjs-2";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { EstablishmentContext } from "src/context/Establishment";

import { getOrdersPage } from "src/services/establishments";

import AnimatedLoader from "../components/AnimatedLoader";
import Paginator from "../components/Paginator";

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
} from "chart.js";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  BarController,
  CategoryScale,
  LinearScale
);

export const Orders = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { selectedEstablishment } = useContext(EstablishmentContext);
  const [idLoadedEstablishment, setIdLoadedEstablishment] = useState<
    number | null
  >(null);

  const [orders, setOrders] = useState<any>(null);
  const [chartData, setChartData] = useState<any>(null);

  const [barChartLegends, setBarChartLegends] = useState<any>(null);
  const [barChartCodeLegends, setBarChartCodeLegends] = useState<any>(null);
  const [barChartData, setBarChartData] = useState<any>(null);

  const [currentPage, setCurrentPage] = useState<number>(1);

  const changePage = (page_number: number) => {
    setCurrentPage(page_number);
  };

  useEffect(() => {
    if (selectedEstablishment) {
      // Go ta page one if selected establishment changes
      if (idLoadedEstablishment !== selectedEstablishment.establishment_id) {
        setCurrentPage(1);
        setBarChartLegends(null);
        setBarChartCodeLegends(null);
        setBarChartData(null);
        setChartData(null);
        setOrders(null);
      } else {
        setOrders(null);
      }

      const relaodOrders = async () => {
        setIdLoadedEstablishment(selectedEstablishment.establishment_id);
        return await getOrdersPage(
          selectedEstablishment.establishment_id,
          currentPage
        );
      };

      relaodOrders()
        .then((result: any) => {
          setOrders(result.data);
          setBarChartLegends(result.data.data_chart);
          setBarChartCodeLegends(result.data.data_chart_code);

          setBarChartData({
            labels: result.data.data_chart.map((i: any) => i.label),
            datasets: [
              {
                label: t("commons.total_amount"),
                data: result.data.data_chart.map((i: any) => i.total_orders),
                backgroundColor: ["rgba(0, 121, 255, 0.5)"],
                borderColor: ["rgba(0, 121, 255, 1)"],
                borderWidth: 1,
                borderRadius: 10,
              },
            ],
          });

          setChartData({
            labels: result.data.data_chart_code.map((i: any) => i.code),
            datasets: [
              {
                label: t("commons.amount"),
                data: result.data.data_chart_code.map(
                  (i: any) => i.total_orders
                ),
                backgroundColor: [
                  "rgba(0, 121, 255, 0.5)",
                  "rgba(0, 121, 255, 0.25)",
                  "rgba(0, 121, 255, 0.125)",
                ],
                borderColor: [
                  "rgba(0, 121, 255, 1)",
                  "rgba(0, 121, 255, 0.5)",
                  "rgba(0, 121, 255, 0.25)",
                ],
                borderWidth: 1,
              },
            ],
          });
        })
        .catch((err) => {
          setBarChartLegends(null);
          setBarChartCodeLegends(null);
          setBarChartData(null);
          setChartData(null);
          setOrders(null);
          console.log(err);
        });
    }
  }, [selectedEstablishment, currentPage]);

  if (!selectedEstablishment) {
    navigate("/establishment");
  } else {
    return (
      <div className="basic-container">
        <div className="sbz-title-container flex-row">
          <span className="title-icon msymbol material-symbols-outlined">
            contract
          </span>

          <div className="flex-column">
            <h1 className="sbz-title">{t("orders.title")}</h1>
            <label>{selectedEstablishment.trade_name}</label>
          </div>
        </div>

        {barChartData &&
          barChartLegends &&
          chartData &&
          barChartCodeLegends && (
            <div className="sbz-page-container">
              <div className="flex-row" style={{ marginBottom: "1rem" }}>
                <div
                  className="white-box flex-row flex-center"
                  style={{
                    margin: 0,
                    width: "50%",
                    padding: "0.5rem 0.75rem",
                    paddingRight: 0,
                    marginRight: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      zIndex: 10,
                      maxHeight: 120,
                      margin: "0",
                      maxWidth: "50%",
                      marginRight: "1rem",
                    }}
                  >
                    <Bar
                      data={barChartData}
                      options={{
                        plugins: {
                          legend: { display: false },
                          tooltip: {
                            mode: "nearest",
                            intersect: false,
                          },
                        },
                        responsive: true,
                        scales: {
                          y: {
                            ticks: {
                              display: false,
                            },
                            grid: {
                              display: false,
                            },
                            border: {
                              display: false,
                            },
                          },
                          x: {
                            ticks: {
                              display: false,
                            },
                            grid: {
                              display: false,
                            },
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    className="flex-column flex-1"
                    style={{
                      fontSize: "0.85rem",
                      overflow: "auto",
                      maxHeight: 100,
                      paddingRight: "0.85rem",
                      marginRight: "0.15rem",
                    }}
                  >
                    <div className="flex-row">
                      <h2 className="flex-1" style={{ fontWeight: "bold" }}>
                        {t("commons.total_staff")}:
                      </h2>
                      <h2 style={{ fontWeight: 900 }}>
                        {barChartLegends
                          .reduce(
                            (acc: number, i: any) => acc + i.total_orders,
                            0
                          )
                          .toLocaleString("fr-FR")}
                      </h2>
                    </div>

                    {barChartLegends.map((item: any) => (
                      <div className="flex-row">
                        <h2 className="flex-1">{item.label}:</h2>
                        <h2 style={{ fontWeight: 700 }}>
                          {item.total_orders.toLocaleString("fr-FR")}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>

                <div
                  className="white-box flex-row flex-center"
                  style={{
                    margin: 0,
                    width: "50%",
                    padding: "0.5rem 0.75rem",
                    paddingRight: 0,
                    marginLeft: "0.5rem",
                  }}
                >
                  <div
                    style={{
                      zIndex: 10,
                      height: "calc(7rem + 100px)",
                      margin: "-50px 0",
                      width: "calc(7rem + 100px)",
                      marginRight: "calc(-100px + 1rem)",
                    }}
                  >
                    <Doughnut
                      data={chartData}
                      options={{
                        plugins: { legend: { display: false } },
                        layout: {
                          autoPadding: false,
                          padding: {
                            right: 100, //set that fits the best
                            top: 0,
                            bottom: 0,
                          },
                        },
                      }}
                    />
                  </div>
                  <div
                    className="flex-column flex-1"
                    style={{
                      fontSize: "0.85rem",
                      overflow: "auto",
                      maxHeight: 100,
                      paddingRight: "0.85rem",
                      marginRight: "0.15rem",
                    }}
                  >
                    <div className="flex-row">
                      <h2 className="flex-1" style={{ fontWeight: "bold" }}>
                        {t("commons.total_type")}:
                      </h2>
                      <h2 style={{ fontWeight: 900 }}>
                        {barChartCodeLegends
                          .reduce(
                            (acc: number, i: any) => acc + i.total_orders,
                            0
                          )
                          .toLocaleString("fr-FR")}
                      </h2>
                    </div>
                    {barChartCodeLegends.map((item: any) => (
                      <div className="flex-row">
                        <h2 className="flex-1">{item.code}:</h2>
                        <h2 style={{ fontWeight: 700 }}>
                          {item.total_orders.toLocaleString("fr-FR")}
                        </h2>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          )}

        {(!orders || !orders?.results) && (
          <div className="sbz-page-container">
            <AnimatedLoader />
          </div>
        )}

        {orders !== null && orders?.results?.length === 0 && (
          <div className="sbz-page-container flex-center">
            <h1 style={{ margin: "2.5rem", fontSize: "1.25rem", opacity: 0.5 }}>
              {t("commons.no_event")}
            </h1>
          </div>
        )}

        {orders !== null && orders?.results?.length > 0 && (
          <div className="sbz-page-container">
            <div className="flex-column table-list">
              <div className="flex-row full-width table-item header-item">
                <div
                  style={{
                    width: "10rem",
                  }}
                >
                  {t("commons.id")} - {t("commons.status")}
                </div>
                <div className="flex-1" >
                  {t("commons.date")}
                </div>
                <div
                  style={{
                    width: "10rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.reduc_novat")}
                </div>
                <div
                  style={{
                    width: "10rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.reduc")}
                </div>

                <div
                  style={{
                    width: "10rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.total_novat")}
                </div>
                <div
                  style={{
                    width: "10rem",
                    textAlign: "right",
                  }}
                >
                  {t("commons.total")}
                </div>
              </div>

              <hr style={{ height: 1, background: "#00000022", margin: 0 }} />

              {orders.results.map((item: any, index: number) => (
                <div
                  key={item.order_id + "_list_100"}
                  className={
                    "table-item flex-row " + (index % 2 === 0 ? "even" : "odd")
                  }
                >
                  <div
                    style={{
                      width: "10rem",
                    }}
                  >
                    <p>
                      <b>{item.order_id}</b> - <i>n°{item.number}</i>
                    </p>

                    <span
                      style={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontWeight: 700,
                        opacity: 0.5,
                      }}
                      className={
                        item.status === "PAYED"
                          ? "success-text"
                          : item.status === "CANCELLED"
                          ? "danger-text"
                          : "warn-text"
                      }
                    >
                      {item.status}
                    </span>
                  </div>

                  <div className="flex-column flex-1">
                    <div className="flex-row full-width">
                      <div className="flex-1">
                        {new Date(item.date).toLocaleString("fr-FR")}
                      </div>

                      <div style={{ width: "10rem", textAlign: "right" }}>
                        {Number(item.total_reduction_novat).toLocaleString(
                          "fr-FR",
                          {
                            maximumFractionDigits: 2,
                            minimumFractionDigits: 2,
                          }
                        )}{" "}
                        €
                      </div>
                      <div style={{ width: "10rem", textAlign: "right" }}>
                        {Number(item.total_reduction).toLocaleString("fr-FR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                      <div style={{ width: "10rem", textAlign: "right" }}>
                        {Number(item.amount_novat).toLocaleString("fr-FR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                      <div
                        style={{
                          width: "10rem",
                          textAlign: "right",
                          fontWeight: 700,
                        }}
                      >
                        {Number(item.amount).toLocaleString("fr-FR", {
                          maximumFractionDigits: 2,
                          minimumFractionDigits: 2,
                        })}{" "}
                        €
                      </div>
                    </div>
                    {item.tax_groups.map((tax_group: any) => (
                      <div
                        className="flex-row full-width"
                        style={{ fontStyle: "italic", opacity: 0.65 }}
                      >
                        <div
                          className="flex-row  flex-1"
                          style={{ justifyContent: "flex-end" }}
                        >
                          <div
                            className="flex-row flex-center"
                            style={{ width: "8rem", textAlign: "right" }}
                          >
                            <span style={{ fontSize: "0.75rem" }}>
                              - {t("commons.vat")}:
                            </span>
                            <div className="flex-1"></div>
                            <b>{Number(tax_group.vat)}%</b>
                          </div>
                        </div>

                        <div style={{ width: "20rem" }}></div>

                        <div style={{ width: "10rem", textAlign: "right" }}>
                          {Number(tax_group.amount_novat).toLocaleString(
                            "fr-FR",
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}{" "}
                          €
                        </div>
                        <div style={{ width: "10rem", textAlign: "right" }}>
                          {Number(tax_group.amount_wvat).toLocaleString(
                            "fr-FR",
                            {
                              maximumFractionDigits: 2,
                              minimumFractionDigits: 2,
                            }
                          )}{" "}
                          €
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>

            <Paginator
              currentPage={currentPage}
              totalItem={orders.total_events}
              itemPerPage={50}
              changeCurrentPage={changePage}
            />
          </div>
        )}
      </div>
    );
  }
};

export default Orders;
