export const establishment = {
  title: `Établissements`,

  pending_csv: `Obtention du csv`,
  success_csv: `Téléchargement en cours`,
  error_csv: `Une erreur s'est produite`,

  download_payments: `Télécharger les paiements en .csv`,
  download_orders: `Télécharger les commandes en .csv`,
  download_staff_tracking: `Télécharger les Staff Tracking en .csv`,
};
