import { auth } from "./auth";
import { commons } from "./commons";
import { database } from "./database";
import { establishment } from "./establishment";
import { orders } from "./orders";
import { payments } from "./payments";
import { staff_tracking } from "./staff_tracking";
import jetEntries from "./jetEntries.json";
import prints from "./prints.json";


export const frFR = {
  auth,
  commons,
  database,
  establishment,
  orders,
  payments,
  staff_tracking,
  jetEntries,
  prints
};
