import { frFR } from "./fr-FR";
import { enUS } from "./en-US";
import { nlNL } from "./nl-NL";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

//empty for now
const resources = {
  fr: {
    translation: frFR,
  },
  en: {
    translation: enUS,
  },
  nl: {
    translation: nlNL,
  },
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ["querystring", "navigator"],
      lookupQuerystring: "lng",
    },
    compatibilityJSON: "v3",
    resources,
    fallbackLng: "fr",
    supportedLngs: ["en", "fr", "nl"],
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

// export const i18n = new I18n({
//     'fr': frFR,
//     'en': enUS,
//     'nl': nlNL
// });

// // Set the locale once at the beginning of your app.
// i18n.locale = Localization.locale;

// // When a value is missing from a language it'll fallback to another language with the key present.
// i18n.enableFallback = true;
// i18n.defaultLocale = 'fr';
