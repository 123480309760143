export const database = {
  title: `Database`,

  generate_dump: `Genereer een Mysql-dump`,
  generate_new_dump: `Genereer een nieuwe Mysql-dump`,
  generating_dump: `Genereren bezig`,
  download_dump: `Download de Mysql-dump`,

  pending_download: `Dump verkrijgen`,
  success_download: `Downloaden bezig`,
  error_download: `Fout tijdens het downloaden`,

  pending_generating: `Laden...`,
  success_generating: `Genereren bezig, het bestand is over enkele minuten beschikbaar`,
  error_generating: `Fout tijdens het genereren`,
};