export const establishment = {
  title: `Vestigingen`,

  pending_csv: `Ophalen van CSV`,
  success_csv: `Downloaden bezig`,
  error_csv: `Er is een fout opgetreden`,

  download_payments: `Download betalingen als .csv`,
  download_orders: `Download bestellingen als .csv`,
  download_staff_tracking: `Download Staff Tracking als .csv`,
};
