import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals";

import { AuthProvider } from "./context/Auth.tsx";
import { ToastContainer } from "react-toastify";
import { EstablishmentProvider } from "./context/Establishment.tsx";
import { UIProvider } from "./context/UI.tsx";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
    <UIProvider>
      <AuthProvider>
        <EstablishmentProvider>
          <App />

          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </EstablishmentProvider>
      </AuthProvider>
    </UIProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
