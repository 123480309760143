import axios from "axios";

// const BASE_URL = "http://localhost:3000";
const BASE_URL = "https://spf-api.symbioz.io";

export const getEstablishments = () => {
  return axios.get(`${BASE_URL}/all_establishments`, { withCredentials: true });
};

export const getOrdersPage = (estab_id: number, page: number) => {
  return axios.get(`${BASE_URL}/establishment/${estab_id}/all_orders/${page}`, {
    withCredentials: true,
  });
};

export const getPaymentsPage = (estab_id: number, page: number) => {
  return axios.get(
    `${BASE_URL}/establishment/${estab_id}/all_payments/${page}`,
    {
      withCredentials: true,
    }
  );
};

export const getJetEntriesPage = (estab_id: number, page: number) => {
  return axios.get(
    `${BASE_URL}/establishment/${estab_id}/all_jet_entries/${page}`,
    {
      withCredentials: true,
    }
  );
};

export const getPrintsPage = (estab_id: number, page: number) => {
  return axios.get(`${BASE_URL}/establishment/${estab_id}/all_prints/${page}`, {
    withCredentials: true,
  });
};

export const getStaffTrackingPage = (estab_id: number, page: number) => {
  return axios.get(
    `${BASE_URL}/establishment/${estab_id}/all_staff_tracking/${page}`,
    {
      withCredentials: true,
    }
  );
};

export const getCsvOrdersExport = (estab_id: number) => {
  return axios
    .get(`${BASE_URL}/establishment/${estab_id}/all_orders_csv`, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "establishment_orders_" + estab_id + ".csv"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const getCsvPaymentExport = (estab_id: number) => {
  return axios
    .get(`${BASE_URL}/establishment/${estab_id}/all_payments_csv`, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "establishment_payments_" + estab_id + ".csv"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const getCsvStaffTrackingExport = (estab_id: number) => {
  return axios
    .get(`${BASE_URL}/establishment/${estab_id}/all_staff_tracking_csv`, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute(
        "download",
        "establishment_staff_tracking_" + estab_id + ".csv"
      ); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};

export const generateSqlDump = (estab_id: number) => {
  return axios.get(`${BASE_URL}/establishment/${estab_id}/generate_sql_dump`, {
    withCredentials: true,
  });
};
export const getSqlDumpAvailability = (estab_id: number) => {
  return axios.get(
    `${BASE_URL}/establishment/${estab_id}/sql_dump_availability`,
    {
      withCredentials: true,
    }
  );
};

export const getSqlDump = (estab_id: number) => {
  return axios
    .get(`${BASE_URL}/establishment/${estab_id}/sql_dump`, {
      withCredentials: true,
      responseType: "blob",
    })
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "dump_establishment_" + estab_id + ".sql"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
};
