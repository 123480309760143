export const database = {
  title: `Base de donées`,

  generate_dump: `Générer un dump Mysql`,
  generate_new_dump: `Générer un nouveau dump Mysql`,
  generating_dump: `Génération en cours`,
  download_dump: `Télécharger le dump Mysql`,

  pending_download: `Obtention du dump`,
  success_download: `Téléchargement en cours`,
  error_download: `Erreur lors du téléchargement`,

  pending_generating: `Chargement...`,
  success_generating: `Génération en cours, le fichier sera disponible dans quelques minutes`,
  error_generating: `Erreur lors de la génération`,
};
