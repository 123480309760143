import React, { useEffect, useRef, useState } from "react";
import { getIsLoggedIn, postLogin, postLogout } from "../services/auth.ts";

type AuthContextType = {
  loadingFinished: boolean;
  isLoggedIn: boolean;
  currentUser: {
    username: string;
  } | null;
  login: ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => Promise<boolean>;
  logout: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  loadingFinished: false,
  isLoggedIn: false,
  login: async () => false,
  logout: async () => {},
  currentUser: null,
});

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [loadingFinished, setLoadingFinished] = useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<{
    username: string;
  } | null>(null);

  const loadingStatus = useRef<boolean>(false);

  useEffect(() => {
    if (!loadingStatus.current) {
      loadingStatus.current = true;

      const fetchData = async () => {
        return await getIsLoggedIn();
      };

      fetchData()
        .then((res) => {
          if (res.data.username) {
            setIsLoggedIn(true);
            setCurrentUser({ username: res.data.username });
          } else {
            setIsLoggedIn(false);
            setCurrentUser(null);
          }
          setLoadingFinished(true);
        })
        .catch((err) => {
          console.log(err);
          loadingStatus.current = true;
          setIsLoggedIn(false);
          setCurrentUser(null);
          setLoadingFinished(true);
        });
    }
  }, []);

  const login = async ({
    username,
    password,
  }: {
    username: string;
    password: string;
  }) => {
    try {
      const loginResult = await postLogin({
        username: username,
        password: password,
      });

      if (loginResult.status === 200) {
        setIsLoggedIn(true);
        setCurrentUser(loginResult.data);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      setIsLoggedIn(false);
      setCurrentUser(null);
      return false;
    }
  };

  const logout = async () => {
    try {
      const logoutRes = await postLogout();
      if (logoutRes.status === 200) {
        setIsLoggedIn(false);
        setCurrentUser(null);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loadingFinished: loadingFinished,
        isLoggedIn: isLoggedIn,
        login: login,
        logout: logout,
        currentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
