import "./styles/App.scss";
import React, { useCallback, useContext } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";

// Pages
import { Auth } from "./ui/pages/Auth.tsx";
import { Establishment } from "./ui/pages/Establishment.tsx";
import { Payments } from "./ui/pages/Payments.tsx";
import { StaffTracking } from "./ui/pages/StaffTracking.tsx";
import { DatabaseAccess } from "./ui/pages/DatabaseAccess.tsx";
import { JetEntries } from "./ui/pages/JetEntries.tsx";
import { Prints } from "./ui/pages/Prints.tsx";

import { Orders } from "./ui/pages/Orders.tsx";
import { Navbar } from "./ui/navbar/Navbar.tsx";

// Context
import { AuthContext } from "./context/Auth.tsx";
import "react-toastify/dist/ReactToastify.css";

import "./translations";

export const App = (props: any) => {
  const { isLoggedIn, loadingFinished } = useContext(AuthContext);

  const getAvailablesRoutes = useCallback(() => {
    if (loadingFinished) {
      if (isLoggedIn) {
        return (
          <Routes>
            <Route path="/" element={withNavbar(Establishment)({ ...props })} />
            <Route
              path="/establishment"
              element={withNavbar(Establishment)({ ...props })}
            />
            <Route
              path="/payments"
              element={withNavbar(Payments)({ ...props })}
            />
            <Route
              path="/staff_tracking"
              element={withNavbar(StaffTracking)({ ...props })}
            />
            <Route
              path="/database"
              element={withNavbar(DatabaseAccess)({ ...props })}
            />
            <Route path="/prints" element={withNavbar(Prints)({ ...props })} />
            <Route
              path="/jet-entries"
              element={withNavbar(JetEntries)({ ...props })}
            />

            <Route path="/orders" element={withNavbar(Orders)({ ...props })} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        );
      } else {
        return (
          <Routes>
            <Route path="/auth" element={withoutNavbar(Auth)({ ...props })} />
            <Route path="*" element={withoutNavbar(Auth)({ ...props })} />
          </Routes>
        );
      }
    }
  }, [isLoggedIn, loadingFinished, props]);

  return <BrowserRouter>{getAvailablesRoutes()}</BrowserRouter>;
};

const withNavbar = (Component: any) => (props: any) =>
  (
    <div className="main-container-navbar">
      <Navbar />
      <div className="main-content">
        <Component {...props} />
      </div>
    </div>
  );
const withoutNavbar = (Component: any) => (props: any) =>
  (
    <div className="main-container-fullscreen">
      <div className="main-content">
        <Component {...props} />
      </div>
    </div>
  );

export default App;
