export const commons = {
  previous: `Précédent`,
  next: `Suivant`,
  options: `Options`,

  no_event: `Aucun évenement pour le moment`,

  select_establishment: 'Selectionner un établissement',

  module: `Module`,
  kind: `Type`,

  total_amount: `Montant total`,
  amount: `Montant`,

  total_events: `Évenemments totaux`,
  total_type: `Total par types`,
  total_staff: `Total par personnel`,
  total_payment_mode: `Total par modes de paiement`,

  id: `ID`,
  status: `Status`,
  date: `Date`,
  reduc_novat: `Réduction HT`,
  reduc: `Réduction TTC`,
  total_novat: `Total HT`,
  total: `Total TTC`,
  vat: `TVA`
};
