export const auth = {
    login: `Inloggen`,
    logout: `Uitloggen`,
  
    username: `Gebruikersnaam`,
    password: `Wachtwoord`,
  
    login_action: `Inloggen`,
    login_error: `Onjuiste gebruikersnaam of wachtwoord`,
  };
  